.label {
    text-transform: capitalize;
}

.header{
    position: sticky;
    top: 0;
    z-index: 1000;
  
  }

.hero {
    background-color: #EBEBEB;
}