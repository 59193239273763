.error{
  color: red;
}
.line{
  height:2px;
  margin-top: 10px;
  border-width:0;
  color:gray;
  background-color:gray;
}

